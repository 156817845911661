import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OpenApp from 'react-open-app'
import { Trans, useTranslation } from 'react-i18next'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'

import { LinkText } from '../LinkText/LinkText'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { useLazyGetPackagesQuery } from '../../store/services/ais/aisApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { LINKS } from '../../constants'

import {
  PricingStyle,
  PromoSection,
  ChoosePackageTitle,
  CustomerTabsContainer,
  CustomerTab,
  ActionButton,
  PricingCard,
  PriceText,
  CardDescription,
  BestValueLabel,
  PriceTitle,
  SmallText,
  CardTerms,
  DividerLine,
  LinkSection,
  LinkButton
} from './Pricing.style'
import PackageItems from './Components/PackageItems'
import { PackageData } from './Components/PackageData'

const {
  REACT_APP_DEEP_LINK,
  REACT_APP_DEEP_LINK_ANDROID,
  REACT_APP_DEEP_LINK_IOS,
  REACT_APP_PLAYSTORE_ANDROID,
  REACT_APP_PLAYSTORE_IOS,
  REACT_APP_REDEEM_REDIRECT_URL,
  REACT_APP_AIS_REDIRECT_URL
} = process.env

const Pricing: FC = () => {
  const { path } = useParams()
  const plans = PackageData()
  const { i18n, t } = useTranslation()
  const { isMobile, isTablet } = useBreakPoints()
  const isIOs = navigator.userAgent.includes('Mac')
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [getPackages, { data: plansInfo }] = useLazyGetPackagesQuery()
  const playStoreUrl = `${isIOs ? REACT_APP_PLAYSTORE_IOS : REACT_APP_PLAYSTORE_ANDROID}`
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('oneD')
  const [spAccountId, setSpAccountId] = useState('')

  const termsUrl = `https://assets.oned.net/doc/term-condition-${i18n.language}.html`

  // Redirect to AIS tab when path is assigned with ais
  useEffect(() => {
    if (path === 'ais') {
      setActiveTab('AIS')
    }
  }, [path])

  useEffect(() => {
    checkSubscription()
    getPackages()
  }, [])

  const routeChange = () => {
    // setIsGTMSignUpSuccessful(true)
    // const path = `/`
    setTimeout(() => {
      navigate(currentLocation as string)
      // dispatch(setStep(1))
      // sessionStorage.setItem('shouldNavigateBack', 'true')
    }, 0)
  }

  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      setSpAccountId(profileResponse?.data?.id ?? '')
      if (profileResponse?.data?.subscription?.subscription_status?.toLowerCase() === 'active') {
        routeChange()
      }
    })
  }

  // Redeem code button click listener function
  const onRedeemCodeClicked = useCallback(() => {
    navigate('/oned/premium/redeem')
  }, [])

  // Join now button click listener function
  const onJoinNowClicked = useCallback(
    (packageId: string) => {
      if (spAccountId.length == 0) {
        navigate('/login', {
          state: { type: 'ACTIVATE', packageId: packageId }
        })
      } else {
        // Redirecting to the vendor activation screen
        window.location.href = `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${spAccountId}&packageId=${packageId}`
      }
    },
    [plansInfo, spAccountId]
  )

  // Link AIS package button click listener
  const onLinkPackageClicked = useCallback(() => {
    if (spAccountId.length == 0) {
      navigate('/login', {
        state: { type: 'ACTIVATE', packageId: '' }
      })
    } else {
      // Redirecting to the vendor activation screen
      window.location.href = `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${spAccountId}`
    }
  }, [spAccountId])

  const onTermsClicked = useCallback(() => {
    const windowRedirection = window.open(LINKS.aisTerms[i18n.language], '_blank')
    if (windowRedirection) {
      windowRedirection.focus()
    }
  }, [])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <PricingStyle className='pricing'>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <ChoosePackageTitle>{t('pricing.choose_package_title')}</ChoosePackageTitle>
      </div>
      {/* navigation tabs */}
      <CustomerTabsContainer>
        <CustomerTab
          className={activeTab === 'oneD' ? 'active' : ''}
          onClick={() => handleTabChange('oneD')}
        >
          {t('pricing.oneD_customers')}
        </CustomerTab>
        <CustomerTab
          className={activeTab === 'AIS' ? 'active' : ''}
          onClick={() => handleTabChange('AIS')}
        >
          {t('pricing.AIS_Fibre3_customers')}
        </CustomerTab>
      </CustomerTabsContainer>

      {/* Detailed section */}
      {activeTab === 'AIS' && (
        <>
          <PromoSection>
            <img
              src={
                isMobile
                  ? 'https://assets.oned.net/oned/ais/promo_image_mobile.png'
                  : 'https://assets.oned.net/oned/ais/promo_image.png'
              }
              alt=''
              className='mock_image'
              style={{ width: '100%' }}
            />
          </PromoSection>
          <Grid
            container
            spacing={3}
            justifyContent='center'
            sx={{
              display: {
                xs: 'inline-table',
                sm: 'flex'
              }
            }}
          >
            {plansInfo?.packages?.map((plan) => (
              <Grid item key={plan.packageId}>
                <PricingCard>
                  {plan.packageBadge.flag && (
                    <BestValueLabel>
                      {i18n.language == 'en'
                        ? plan.packageBadge.badgeEN
                        : plan.packageBadge.badgeTH}
                    </BestValueLabel>
                  )}
                  <PriceTitle>
                    {i18n.language == 'en'
                      ? plan.packageDisplayInfo.nameEN
                      : plan.packageDisplayInfo.nameTH}
                  </PriceTitle>
                  <SmallText>
                    {i18n.language == 'en'
                      ? plan.packageDisplayInfo.detailEN
                      : plan.packageDisplayInfo.detailTH}
                  </SmallText>
                  <PriceText>{`${plan.packagePrice.promotion} ${t('pricing.thb')}/${
                    i18n.language == 'en' ? plan.packagePrice.periodEN : plan.packagePrice.periodTH
                  }`}</PriceText>
                  <SmallText>{`(${t('pricing.from')} ${plan.packagePrice.normal} ${t(
                    'pricing.thb'
                  )}/${
                    i18n.language == 'en' ? plan.packagePrice.periodEN : plan.packagePrice.periodTH
                  })`}</SmallText>
                  <CardDescription>
                    {i18n.language == 'en'
                      ? plan.packageDisplayInfo.descriptionEN
                      : plan.packageDisplayInfo.descriptionTH}
                  </CardDescription>
                  <CardTerms onClick={() => onTermsClicked()}>
                    {t('pricing.terms_condition')}
                  </CardTerms>
                  <ActionButton onClick={() => onJoinNowClicked(plan.packageId)}>
                    {t('pricing.join_now')}
                  </ActionButton>
                </PricingCard>
              </Grid>
            ))}
          </Grid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '3rem 0'
            }}
          >
            <DividerLine />
            <span
              style={{ margin: '0 10px', color: 'white', fontFamily: 'Prompt', fontWeight: 700 }}
            >
              {t('pricing.or')}
            </span>
            <DividerLine />
          </div>

          <LinkSection>
            <span style={{ fontFamily: 'Prompt' }}>{t('pricing.link_your_ais_package')}</span>
            <LinkButton onClick={onLinkPackageClicked}>{t('pricing.click')}</LinkButton>
          </LinkSection>
          {(isMobile || isTablet) && <PackageItems plans={plans} />}
        </>
      )}
      {isMobile || isTablet ? (
        activeTab === 'oneD' && (
          <Grid
            className='mobile_qr'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
          >
            <Grid>
              <img
                src='https://assets.oned.net/imgs/oned_premium_mockup.png'
                alt=''
                className='mock_image'
              />
            </Grid>
            <Grid>
              <Typography className='choose_plan'>{t('pricing.choose_plan')}</Typography>
              <OpenApp
                className='deep_link'
                href={playStoreUrl}
                android={`${REACT_APP_DEEP_LINK}/${REACT_APP_DEEP_LINK_ANDROID}`}
                ios={`${REACT_APP_DEEP_LINK}/${REACT_APP_DEEP_LINK_IOS}`}
              >
                {t('pricing.click_here')}
              </OpenApp>
            </Grid>
            <Grid item>
              <LinkSection>
                <span>
                  <Typography className='redeem_code'>{t('pricing.redeem_code')}</Typography>
                </span>
                <Button variant='contained' onClick={onRedeemCodeClicked}>
                  {t('pricing.click_here')}
                </Button>
              </LinkSection>
            </Grid>
            <PackageItems plans={plans} />
          </Grid>
        )
      ) : (
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          direction='column'
          spacing={5}
          className='pricing_container'
        >
          {activeTab === 'oneD' && (
            <>
              <Grid
                item
                container
                direction='row'
                justifyContent='center'
                className='qr_container'
                flexWrap='nowrap'
              >
                <Grid>
                  <Paper
                    variant='outlined'
                    sx={{ background: '#000', border: '1px solid #282828', borderRadius: '30px' }}
                  >
                    <img
                      src='https://assets.oned.net/oned/subscription_page_website-1176x558.png'
                      alt=''
                      className='mock_image'
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid item>
                <LinkSection>
                  <span>
                    <Typography className='redeem_code'>{t('pricing.redeem_code')}</Typography>
                  </span>
                  <Button variant='contained' onClick={onRedeemCodeClicked}>
                    {t('pricing.click_here')}
                  </Button>
                </LinkSection>
              </Grid>
              <Grid item>
                <Typography variant='body1' className='pricing_terms'>
                  <Trans
                    i18nKey='pricing.terms'
                    components={{
                      terms: <a href={termsUrl} target='_blank' rel='noreferrer' />,
                      privacy: <LinkText linkKey='privacy' textKey='legal.privacy' defaultText='' />
                    }}
                  />
                </Typography>
              </Grid>
            </>
          )}
          <Grid item flex={1} width={'100%'}>
            <PackageItems plans={plans} />
          </Grid>
        </Grid>
      )}
    </PricingStyle>
  )
}

export default Pricing

export const RedeemRoute: FC = () => {
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [spAccountId, setSpAccountId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getProfileData()
      .then((profileResponse) => {
        setSpAccountId(profileResponse?.data?.id ?? '')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getProfileData])

  const redeemPageCallBack = useCallback(() => {
    const location = '/oned/premium/redeem'
    localStorage.setItem('redeemPage', 'true')

    if (spAccountId.length === 0) {
      navigate('/login', {
        state: { type: 'REDEEM', packageId: '', from: location },
        replace: true
      })
    } else {
      const redirectUrl = `${REACT_APP_REDEEM_REDIRECT_URL}?spAccountId=${spAccountId}`
      window.location.replace(redirectUrl)
    }
  }, [spAccountId, navigate])

  useEffect(() => {
    if (!isLoading) {
      redeemPageCallBack()
    }
  }, [isLoading, redeemPageCallBack])

  return null
}
