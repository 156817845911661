import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useState } from 'react'
import { Box, createTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import LanguageSelector from '../../LanguageSelector/LanguageSelector'
import useSubscriptionStatus from '../../Common/SubscriptionStatus/SubscriptionStatus'
import { MenuItem } from '../../../types/config'
import { setLocation } from '../../../store/slices/menuSlice'
import { userSelector } from '../../../store/slices/config/configSelectors'
import { RootState } from '../../../store'
import Version from '../../../../package.json'

interface MenuNavigationI {
  isMobile?: boolean
  onMobileClick?: () => void
  className?: string
  mobileMenuItems?: MenuItem[]
}

const MenuNavigation: FC<MenuNavigationI> = ({ onMobileClick, className, mobileMenuItems }) => {
  const { t } = useTranslation()
  const user = useSelector(userSelector)
  const theme = createTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false)
  const { headerMenuItems, headerSubMenuItems } = useSelector((state: RootState) => state.menu)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isSubscriptionUser = useSubscriptionStatus()
  const { isLogged } = useSelector((state: RootState) => state.userProfile)

  const getNavigationPath = (item: { path: string; isParent?: boolean }) => {
    if (item.isParent) return
    if (!user) {
      if (item.path === '/my-list' && pathname !== '/login') {
        return '/login'
      } else {
        return item.path
      }
    }
    return item.path
  }
  const translate = (str, nonBreak = false) => {
    const strTranalated = t(str)
    if (nonBreak) {
      return strTranalated.replace(/ /g, '\u00A0')
    }
    return strTranalated
  }

  const tagManagerClick = (menuTitle) => {
    let tagManagerArgs = {}
    if (menuTitle?.toLowerCase().replace(/\s/g, '') === 'originaloned') {
      tagManagerArgs = {
        dataLayer: {
          event: 'original_menu',
          event_category: 'original_menu',
          event_action: 'click_menu',
          event_label: menuTitle?.toLowerCase().replace(/\s/g, '')
        }
      }
    } else {
      tagManagerArgs = {
        dataLayer: {
          event: 'header_menu',
          event_category: 'header_menu',
          event_action: 'click_menu',
          event_label: menuTitle?.toLowerCase().replace(/\s/g, '')
        }
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const handleNavClick = (event, item) => {
    if (item.path === '/more') {
      setShowSubMenu(!showSubMenu)
      event.preventDefault()
    } else {
      setShowSubMenu(false)
      tagManagerClick(item.title)
      dispatch(setLocation(item.path))
    }
  }

  return (
    <div className={`menu-wrapper ${className ? className : ''}`}>
      <ul className='header-nav-menu'>
        {isMobileScreen &&
          mobileMenuItems
            ?.filter(
              (obj) => obj?.title?.toLowerCase() !== translate('nav.activities').toLowerCase()
            )
            .map((item, index) => {
              if (!item?.isParent) {
                return (
                  <li
                    key={item.title + index}
                    onClick={() => {
                      tagManagerClick(item.title)
                      dispatch(setLocation(getNavigationPath(item) as string))
                      onMobileClick && onMobileClick()
                    }}
                  >
                    <NavLink className='header-link-color' to={getNavigationPath(item) as string}>
                      {translate(item.title, true)}
                    </NavLink>
                  </li>
                )
              }
              return null
            })}

        {!isMobileScreen &&
          headerMenuItems.map((item, index) => {
            if (isLogged === 'Y') {
              if (item.path === '/oned/premium') {
                if (isSubscriptionUser) return null
              }
            }
            return (
              <li
                key={item.title + index}
                // onMouseEnter={() => item.isParent && setShowSubMenu(true)}
                // onMouseLeave={() => item.isParent && setShowSubMenu(false)}
              >
                <NavLink
                  onClick={(event) => {
                    handleNavClick(event, item)
                  }}
                  className={`header-link-color ${item.isParent ? 'more' : ''} ${
                    item?.title?.startsWith('one') ? 'oneD' : ''
                  }`}
                  to={getNavigationPath(item) as string}
                >
                  {translate(item.title, true)}
                  {item.isParent && <ArrowDropDownRoundedIcon />}
                </NavLink>
                {showSubMenu && item.isParent && (
                  <ul className='header-sub-menu'>
                    {headerSubMenuItems
                      .filter((obj) => obj.title !== translate('nav.faq'))
                      .map((subItem) => (
                        <li className='header-sub-items' key={subItem.title + index}>
                          <NavLink
                            onClick={() => {
                              tagManagerClick(subItem.title)
                              dispatch(setLocation(subItem.path))
                              setShowSubMenu(false)
                            }}
                            to={subItem.path}
                          >
                            {translate(subItem.title, true)}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            )
          })}
      </ul>
      <Box
        sx={{
          display: { sm: 'none' }
        }}
      >
        <div className='lang_mobile'>
          <LanguageSelector />
        </div>
        <span className='version_text'>Version {`${Version.version}`}</span>
      </Box>
    </div>
  )
}
export default React.memo(MenuNavigation)
